<template>
  <div>
    <slideshow-module :slides="slides"></slideshow-module>
    <text-module>
      <div style="padding: 10px 10px 10px 10px; color: #808080">
        <div style="margin-top: 30px; line-height: 18px; height: 340px">
          <!-- UniverseTheGame/images/picFDDDFDB924F7329E151A42535600395F.png -->
          <img
            src="@/assets/the-game/assembly-leader.png"
            style="float: right; margin-left: 10px"
          /><br />
          <!-- UniverseTheGame/images/pic8B446DBDAD108F22E65EE2E2650AA5C6.png -->
          <img
            src="@/assets/the-game/assembly-logo.png"
            style="float: right; margin-top: 24px"
          /><br />
          <span
            style="
              font-size: 20px;
              color: #fff;
              display: block;
              margin-bottom: 20px;
            "
            >The Assembly Faction</span
          ><br />The Assembly Faction is the first of the 4 Nexus Force
          alliances. Their members believe that the act of creation naturally
          sets back the chaotic destructive nature of the Maelstrom. And they
          are challenged to push the limits of imagination, exploring new styles
          and new ways of construction. They consider themselves to be the
          foundation of the Nexus Force. And even though they may not be as
          brave as the Sentinels or as daring as the Venture League, they are
          brilliant architects and clever engineers who are highly respected for
          their skills. <br /><br />Their leader the eccentric
          <span style="color: #fff">Doctor Overbuild</span> is genius and very
          intelligent – like a true copy of Einstein. His passion is building
          and pushing the uses of imagination. He spends months at a time locked
          in his building laboratory, creating the visions in his head.<br />
        </div>
        <div style="margin-top: 30px; line-height: 18px; height: 380px">
          <br />
          <!-- UniverseTheGame/images/picBBE982B85EF6298677639E3B394DA5B2.png -->
          <img
            src="@/assets/the-game/venture-leader.png"
            style="float: right; margin-left: 10px"
          /><br />
          <!-- UniverseTheGame/images/pic8A2C979729A592D64736295D7D1FC92C.png -->
          <img
            src="@/assets/the-game/venture-logo.png"
            style="float: right; margin-top: 24px"
          /><br />
          <span
            style="
              font-size: 20px;
              color: #fff;
              display: block;
              margin-bottom: 20px;
            "
            >The Venture League</span
          >
          <br />The Venture League is the second alliance of the Nexus Force.
          Its members are a composition of adventurers and explorers who want to
          get to the bottom of all of the mysteries filling up the Universe. No
          matter if it's exploring long-lost civilizations of the First Builders
          or going where no Minifigure has ever gone before, the Venture League
          has always been the first to discover it. They're simply on a mission
          to unravel every mystery the Universe has to offer. <br /><br />Their
          leader, the cheerful <span style="color: #fff">Hael Storm</span>, has
          a great sense of humor and is always ready with a wisecrack. He's a
          born problem solver and in times of danger, Storm will always rise to
          the challenge. He's a fun, adventurous pirate with an attitude. His
          specialty is the sea and he is a seasoned explorer of all the oceans
          in the Universe.<br />
        </div>
        <div style="line-height: 18px; height: 370px">
          <br />
          <!-- UniverseTheGame/images/pic23D63E15398C013E0F0C8F33F0EC59E8.png -->
          <img
            src="@/assets/the-game/sentinel-leader.png"
            style="float: left; margin-right: 20px"
          /><br />
          <!-- UniverseTheGame/images/pic1E33CD93FF9F537C18AF8B610B418531.png -->
          <img
            src="@/assets/the-game/sentinel-logo.png"
            style="float: right; margin-top: 24px"
          /><br />
          <span
            style="
              font-size: 20px;
              color: #fff;
              display: block;
              margin-bottom: 20px;
            "
            >The Sentinels</span
          ><br />The Sentinels is the third alliance of the Nexus Force and
          their mission is to protect the weak, defend the good and serve
          justice to all. Integrity and Honor are the core of their operations
          and their heroic performance overshadows all evil. <br />Members of
          The Sentinels are the most force-full Minifigures of the Nexus Force.
          What they lack in pure imagination, they make up with strength and
          courageous willpower. They are known and respected as the most
          honorable warriors in the Universe.<br /><br />Their leader, the
          charismatic and brave <span style="color: #fff">Duke Exeter</span>, is
          an inspiration to everyone around him. He refuses to compromise his
          principles, and so far he has always found a way to achieve victory
          with honor. Always trusting that the right will win, he's big on
          giving everyone second chances. And he is always prepared to take on
          challenges like a true boy-scout.<br />
        </div>
        <div style="margin-top: 30px; line-height: 18px; height: 360px">
          <br />
          <!-- UniverseTheGame/images/pic660F4695BAC5ED463AA9D08EB4FD02DB.png -->
          <img
            src="@/assets/the-game/paradox-logo.png"
            style="float: left; margin-right: 20px"
          /><br />
          <!-- UniverseTheGame/images/pic8D617E21248BEA673A92E4FDDEFD3E11.png -->
          <img
            src="@/assets/the-game/paradox-leader.png"
            style="float: right; margin-top: 24px"
          /><br />
          <span
            style="
              font-size: 20px;
              color: #fff;
              display: block;
              margin-bottom: 20px;
            "
            >Paradox</span
          ><br />Paradox is the fourth and last alliance of the Nexus Force.
          Paradox members closely study the Maelstrom power in an effort to find
          the evil within it. They believe that the battlefield is no place for
          honor and use whatever means are necessary to achieve victory. Paradox
          has got a reputation for being secretive about their operations. They
          are sneaky in their attacks and highly effective in all their
          disciplines. Though Paradox has never broken the trust of the other
          Factions they are still looked at with an eye of suspicion.<br /><br />Their
          leader, the mysterious ninja warrior
          <span style="color: #fff">Vanda Darkflame</span>, believes that to
          defeat an enemy you must understand it and, if possible, turn its own
          power against it. She is cynical, selfish, decisive, and always fights
          for what she feels is right, no matter what anyone else may think
          about her heartless methods.<br />
        </div>
        <br />
      </div>
    </text-module>
  </div>
</template>

<script lang="ts">
import { SlideOptions } from "@/options";
const SLIDES: SlideOptions[] = [
  {
    // download145EF1D8DC798F3666714FCB9C16A1C7
    img: require("@/assets/slideshow-items/factions-movie.jpg"),
    video: require("@/assets/slideshow-items/factions.mp4"),
    videoPos: "B",
    headline1: "In-Game Video",
    headline2: "The Factions",
    text1:
      "Build your custom LEGO Universe minifigure and join the Nexus Force, an elite team of heroes united to defeat the Maelstrom, a dark menace trying to destroy imagination!",
    //link=<a href="http://universe.lego.com/en-us/media/default.aspx?section=141010&category=141100&contentID=211836">Watch the video</a>&
    link: "/media/s/141010/c/141100/id/211836",
    linkText: "Watch the video",
    width: 280,
  },
];

export default {
  setup() {
    const slides = SLIDES;
    return { slides };
  },
};
</script>