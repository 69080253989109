
import { SlideOptions } from "@/options";
const SLIDES: SlideOptions[] = [
  {
    // download145EF1D8DC798F3666714FCB9C16A1C7
    img: require("@/assets/slideshow-items/factions-movie.jpg"),
    video: require("@/assets/slideshow-items/factions.mp4"),
    videoPos: "B",
    headline1: "In-Game Video",
    headline2: "The Factions",
    text1:
      "Build your custom LEGO Universe minifigure and join the Nexus Force, an elite team of heroes united to defeat the Maelstrom, a dark menace trying to destroy imagination!",
    //link=<a href="http://universe.lego.com/en-us/media/default.aspx?section=141010&category=141100&contentID=211836">Watch the video</a>&
    link: "/media/s/141010/c/141100/id/211836",
    linkText: "Watch the video",
    width: 280,
  },
];

export default {
  setup() {
    const slides = SLIDES;
    return { slides };
  },
};
